$font-family-sans-serif: 'proxima-nova', sans-serif;
$gray-dark: black;
$link-hover-decoration: none;
$list-inline-padding: 10px;

@import 'node_modules/bootstrap/scss/bootstrap';

// WordPress Alignment Classes
img.alignright {float:right; margin:0 0 1em 1em}
img.alignleft {float:left; margin:0 1em 1em 0}
img.aligncenter {display: block; margin-left: auto; margin-right: auto}
a img.alignright {float:right; margin:0 0 1em 1em}
a img.alignleft {float:left; margin:0 1em 1em 0}
a img.aligncenter {display: block; margin-left: auto; margin-right: auto}

blockquote {
    font-style: italic;
    padding: $spacer;
}

.top-nav__wrapper2{
	background-color: #000;
    color: #fff;
    position: sticky;
    text-align: center;
    top: 56px;
    padding: 5px;
    font-size: 20px;
    z-index: 5000;
}

body {
    background-attachment: fixed;
    background-color: black;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    
    @include media-breakpoint-down(sm) {
        background-attachment: scroll;
        background-position: top ($spacer * 7) center;
        background-size: 120% auto;
    };
    
    &.black-no-image {
        
        background-color: black;
        background-image: initial !important;
    }
}

.card {
    
    border: 1px solid black;
    border-radius: 0;
    
    .card-block.\--stylists {
        text-transform: uppercase;
        
        .current-stylist {
            font-weight: bold;
        }
    }
    .card-header {
        
        background-color: black;
        border-bottom: 1px solid black;
        color: white;
        text-transform: uppercase;
        
        &:first-child {
            
            border-radius: 0;
        }
    }
}

.embed-responsive {
    @extend .m-b-1;
}

.entry {
    
    &__footer {
        clear: both;
    }
}

.footer {
    @extend .row;

    &__map {
        position: relative;
        width: 100%;

        &:before {
            content: '';
            display: block;
            padding-top: 56.25%;
        }

        &-iframe {
            position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
        }

        &-wrapper {
            @extend .col-md-4;
            @extend .m-b-1;
        }
    }

    &__text {
        @extend .col-md-8;

        color: white;
        text-transform: uppercase;

        a {
            color: white;

            &:hover {
                color: $brand-primary;
            }
        }
    }
    
    &__tseg-logo {
        @extend .col-xs-12;
        
        text-align: center;
    }

    &__wrapper {
        @extend .p-b-2;
        @extend .p-t-2;

        background-color: $gray-dark;
    }
}

.gform_wrapper {
    @extend .p-b-1;
    
    .gform_body .gfield {
        @extend .form-group;
        @extend .text-xs-left;
        
        input {
            @extend .form-control;
            
            border-radius: 0;
        }
        
        label {
        }
        
        textarea {
            @extend .form-control;
            
            border-radius: 0;
            height: $spacer * 6;
        }
    }
    
    .gfield_error input[type=email],
    .gfield_error input[type=number],
    .gfield_error input[type=password],
    .gfield_error input[type=tel],
    .gfield_error input[type=text],
    .gfield_error input[type=url],
    .gfield_error textarea {
        
        border: 1px solid #a94442 !important;
    }
    
    .gform_fields {
        @extend .m-a-0;
        @extend .p-a-0;
        
        list-style-type: none;
    }
    
    .gform_footer input[type=submit] {
        @extend .btn;
        @extend .btn-block;
        
        background-color: black;
        border: 1px solid black;
        border-radius: 0;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        
        &:focus,
        &:hover {
            background-color: white;
            border: 1px solid black;
            color: black;
        }
    }
    
    .ginput_complex {
        
        .address_city {
            
            display: inline-block;
            width: 100%;
            
            @include media-breakpoint-up(md) {
                
                margin-right: 2%;
                width: 57%;
            };
            
            @include media-breakpoint-up(lg) {
                
                margin-right: 2%;
                width: 67%;
            };
        }
        .address_state {
            
            display: inline-block;
            width: 100%;
            
            @include media-breakpoint-up(md) {
                width: 41%;
            };
            
            @include media-breakpoint-up(lg) {
                width: 31%;
            };
            
            select {
                
                border: 1px solid black;
                border-radius: 0;
                margin-bottom: 5px;
            }
        }
        input[type=text] {
            
            display: block;
            margin-bottom: 5px;
        }
        
        label {
            
            display: block;
            font-size: 16px;
            margin-bottom: 0;
        }
    }
    
    .validation_error,
    .validation_message {

        background-color: #f2dede;
        border: 1px solid transparent;
        border-color: #ebccd1;
        color: #a94442;
        margin-bottom: $spacer;
        padding: $spacer;
    }
    .validation_message {

        margin-top: $spacer / 4;
        padding: ($spacer / 3) ($spacer / 2);
    }
}

.header {
    @extend .navbar;
    @extend .navbar-full;
    @extend .p-b-1;
    @extend .p-t-1;

    background-color: rgba(255,255,255,0.75);
    border-radius: 0;
    
    @include media-breakpoint-down(sm) {
        background-color: white;
    };

    &__link {
        @extend .nav-link;
        
        color: $gray-dark !important;
    }

    &__list {
        @extend .nav;
        @extend .navbar-nav;
        
        // Fixes glitch where dropdown items aren't clickable on mobile.
        .dropdown-backdrop {
            
            position: static;
        }
        
        &-item {
            @extend .nav-item;

            text-transform: uppercase;
            
            @include media-breakpoint-down(sm) {
                float: none !important;
                
                & + .header__list-item {
                    margin-left: 0 !important;
                }
            };
            
            @include media-breakpoint-up(lg) {
                line-height: 37px;
            };
            
            .dropdown-menu {
                background-color: rgba(255,255,255,0.65);
                border: 1px solid black;
                border-radius: 0;
                
                @include media-breakpoint-down(sm) {
                    
                    border-left: none;
                    border-right: none;
                    float: none;
                    margin: 0;
                    position: static;
                };
            }
            
            .dropdown-menu & {
                @extend .dropdown-item;
                @extend .p-y-0;
                              
                & + .header__list-item {
                    margin-left: 0;
                }
            }
        }
    }

    &__logo {

        a {
            @extend .navbar-brand;
        }
        
        img {
            max-width: 251px; /* width of design logo */
            
            @include media-breakpoint-down(md) {
                width: 170px;
            };
        }
    }
    
    &__mobile-button {
        @extend .hidden-md-up;
        @extend .navbar-toggler;
        @extend .pull-xs-right;
        
        line-height: $font-size-base * 1.8;
        
        @include media-breakpoint-down(sm) {
            line-height: $font-size-base;
        };
    }

    &__nav {
        @extend .navbar-toggleable-sm;
        @extend .pull-md-right;
        
        @include media-breakpoint-down(sm) {
            clear: both;
            padding-top: $spacer;
        };
    }

    &__wrapper {
    }
}

.home-box {
    @extend .col-md-12;

    &__card {
        @extend .p-a-1;
        @extend .text-xs-center;

        background-color: white;
        border: 1px solid $gray-dark;
        position: relative;
        z-index: 5;

        &-header {
            
            font-size: $font-size-h5;
            font-weight: bold;
            text-transform: uppercase;
        }

        &-list {
            @extend .list-inline;
            @extend .m-b-0;

            li {
                @extend .list-inline-item;

                text-transform: uppercase;
            }
        }
        
        &-logos {
            display: flex;
            
            img {
                align-self: center;
                flex-basis: 20%;
                height: auto;
                padding: 15px;
                width: 20%;
            }
        }
    }

    &__wrapper {
        @extend .hidden-sm-down;
        
        border-bottom: ($spacer * 2) solid black;
        position: relative;

        &:after {
            background-color: $gray-dark;
            content: '';
            display: block;
            height: 50%;
            position: absolute;
                top: 50%;
            width: 100%;
        }
        
        &.\--home {
            border-bottom: none;
        }
    }
}

.home-content {
    @extend .row;

    &__header {
        @extend .col-md-12;
        @extend .text-xs-center;

        text-transform: uppercase;

        h1 {
            font-size: $font-size-h2;
            font-weight: bold;
        }

        h2 {
            font-size: $font-size-h2;
            font-weight: bold;
        }
    }

    &__paragraph {
        @extend .col-md-6;
        @extend .text-xs-center;
    }
    
    &__product {
        @extend .col-xs-12;
        @extend .col-md-2;
        @extend .text-xs-center;
        
        @include media-breakpoint-up(md) {
            width: 16%;
        }
        

        img {
            max-width: 100%;
        }
        @include media-breakpoint-down(sm) {
            margin-top:10px;
            img {
                max-width:50%;
            }
        }
    }

    &__stylist {
        @extend .text-xs-center;
        
        margin-right: 30px;
        width: 25%;
        
        @include media-breakpoint-down(sm) {
            width: 75%;
        };

        &-name {
            color: black;
            font-weight: bold;
            text-transform: uppercase;
        }

        &-photo {
            @extend .img-fluid;

            margin-bottom: ($spacer / 2);
        }

        &-title {
            color: black;
            font-style: italic;
            text-transform: uppercase;
            
            @include media-breakpoint-down(sm) {
                font-size: $font-size-base * 0.6;
            };
        }
    }

    &__wrapper {
        @extend .p-b-2;
        @extend .p-t-2;

        background-color: white;

        &.\--dark {
            background-color: $gray-dark;
            color: white;
        }
    }
}

.home-feature {
    @extend .p-b-3;
    @extend .row;

    &:last-of-type {
        @extend .p-b-0;
    }

    &__content {
        @extend .col-md-8;

        .\--right & {
            @extend .col-md-pull-4;
        }

        h3 {
            font-size: $font-size-h3;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    &__photo {
        @extend .col-md-4;
        
        margin-bottom: ($spacer / 2);

        .\--right & {
            @extend .col-md-push-8;
        }

        img {
            @extend .img-fluid;
        }
    }

    &__wrapper {
        @extend .p-b-3;
        @extend .p-t-3;

        background-color: $gray-dark;
        color: white;
    }
}

.home-slideshow {
    
    height: 100%;
    position: absolute;
        left: 0;
        top: 0;
    width: 100%;
    z-index: -1;
    
    &__slide {
        
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        opacity: 0;
        position: absolute;
            left: 0 !important;
            top: 0;
        transition: opacity 0.3s ease-in-out;
        width: 100%;
        
        @include media-breakpoint-down(sm) {
            background-attachment: scroll;
            background-position: top ($spacer * 6) center;
            background-size: 120% auto;
        };
        
        &.\--one {
            background-image: url('../img/homepage-3.jpg');
        }
        
        &.\--two {
            background-image: url('../img/purple-dress-2.jpg');
        }
        
        &.\--three {
            background-image: url('../img/rusted-metal-2.jpg');
        }
        
        &.is-selected{
            opacity: 1;
        }
    }
    
    .flickity-slider {
        transform: none !important;
    }
}



.image-layer {
    background-attachment: fixed;
    background-image: url('https://via.placeholder.com/1600x800');
    background-position: center center;
    background-size: cover;
    position: relative;
    width: 100%;
    
    @include media-breakpoint-down(sm) {
        background-attachment: scroll;
    };

    &:before {
        content: '';
        display: block;
        padding-top: 25%;
        
        @include media-breakpoint-down(sm) {
            padding-top: ($spacer * 10);
        };
    }

    &--main-graphic {
        @extend .image-layer;
        
        background-color: transparent;
        background-image: none;

        &:before {
            @extend .image-layer:before;
            
            padding-top: 44%;
            @media (max-width:480px) {
	            padding-top: 32%;
            }
        }
    }
}

.main {
    @extend .p-b-3;
    @extend .p-t-3;
    @extend .row;
    
    &__content {
        @extend .col-md-7;
        @extend .col-lg-8;
        @extend .m-b-1;
        
        h1, h2 {
            text-transform: uppercase;
        }
        
        h3 {
            color: #555555;
            text-transform: uppercase;
        }
        
        h1:not(:first-of-type),
        h2,
        h3 {
            
            margin-bottom: $spacer * 1;
            margin-top: $spacer * 2;
        }
    }
    
    &__sidebar {
        @extend .col-md-5;
        @extend .col-lg-4;
        position:relative;
        .sidebar-wrapper {
/*
            &.fixed {
                position:fixed;
                top:0;
                max-width:350px;
            }
*/
        }
    }
    
    &__wrapper {
        background-color: #ffffff;
    }
}

.stylist-box {
    margin-bottom: 30px;
    position: relative;
}
    .stylist-box h2 {
        margin: 0;
    }
    .stylist-box img {
        height: auto;
        width: 100%;
    }
    .stylist-box__overlay {
        background-color: rgba(0,0,0,0.3);
        color: #ffffff;
        height: auto;
        padding: 15px 10px;
        position: absolute;
            bottom: 0;
            left: 0;
        width: 100%;
    }
    .stylist-box p {
        font-style: italic;
        margin: 0;
    }


table {
    
    margin-bottom: $spacer * 1.5;
    margin-top: $spacer * 1.5;
}
    

.top-nav {
    @extend .row;

    &__contact {
        @extend .col-xs-12;
        @extend .col-sm-8;
    }

    &__list {
        @extend .list-inline;
        @extend .m-b-0;

        &-item {
            @extend .list-inline-item;
            @extend .hidden-xs-down;

            text-transform: uppercase;
            
            a {
                color: white;
            }
            
            &--phone {
                @extend .top-nav__list-item;
                
                display: inline-block !important;
            }
        }
    }

    &__social {
        @extend .col-sm-4;
        @extend .hidden-xs-down;
        @extend .text-xs-right;
    }

    &__wrapper {
        @extend .container-fluid;
        @extend .p-b-1;
        @extend .p-t-1;

        background-color: rgba(0,0,0,0.5);
        color: white;
        position: sticky;
            top: 0;
        z-index: $zindex-navbar;
        
        @include media-breakpoint-down(sm) {
            background-color: black;
        };
    }
}

ul.pagination {
    
    li.page-item {
        
        .page-link {
            border-radius: 0;
            color: black;
        }
    }
    
    li.page-item.active {
        
        .page-link {
            background-color: black;
            border-color: black;
            color: white;
        }
    }
}

.holiday-message {
	background: rgba(0, 0, 0, 0.8);
    color: #ed9123;
    padding: 20px 0;
    text-align: center;
    text-shadow: 1px 1px 1px black;
    span {
	    font-size: 20px;
	    @media (max-width:768px) {
		    font-size: 18px;
	    }
	    @media (max-width:480px) {
		    font-size: 0.9rem;
	    }
    }
}

/*Warning Message*/
.homepage-message p  {margin-bottom: 0;}
.homepage-message .alert {margin-bottom: 0; padding: 20px 0;}
#warning-message {background: rgb(0,0,0.9); z-index: 9999;}
.message-trigger {font-weight: 600;}
.message-trigger:hover {cursor: pointer;}
#warning-message .modal-dialog {
	margin: 9% auto;
    background: transparent;
    border: none;
    box-shadow: none;
}
 #warning-message .modal-content {
	background: transparent;
    color: #fff;
    border: none;
    box-shadow: none;
}
#warning-message .modal-header {border-color: #bbac77;}
#warning-message  .close{
	color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    display: inline;
    width: auto;
    opacity: 1;
}
#warning-message  .close span {font-size: 35px;}
 
 @media (max-width:575px) {
	 #warning-message h2 {font-size: 23px;}
	 #warning-message p {font-size: 18px;}
	 #warning-message .close {right:7%;}
	 
 }
 
 @media (min-width:767.98px) {
	.main__sidebar {
	position: -webkit-sticky;
    position: sticky;
    top: 115px;
    height: 100%;
}
 }
 
